var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1000px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SETT060G010.013"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _c("FreightBillInfo", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.freightBillInfo.isShow.info,
                  expression: "freightBillInfo.isShow.info",
                },
              ],
              ref: "FBInfo",
              attrs: {
                "dp-id": _vm.freightBillInfo.dpId,
                "bl-list": _vm.freightBillInfo.blList,
              },
            }),
            _c("FreightBillMethod", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.freightBillInfo.isShow.method,
                  expression: "freightBillInfo.isShow.method",
                },
              ],
              ref: "FBMethod",
              attrs: {
                "err-arr": _vm.errArr.method,
                "clear-arr": _vm.clearArr.method,
                "dp-id": _vm.freightBillInfo.dpId,
                "input-default-data": _vm.freightBillInfo.inputDefaultData,
                "btn-type": _vm.freightBillInfo.btnType,
                "validation-method": _vm.validationMethod,
              },
            }),
            _c("FreightBillTax", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.freightBillInfo.isShow.tax,
                  expression: "freightBillInfo.isShow.tax",
                },
              ],
              ref: "FBTax",
              attrs: {
                "err-arr": _vm.errArr.taxTemplate,
                "clear-arr": _vm.clearArr.taxTemplate,
                "dp-id": _vm.freightBillInfo.dpId,
                "input-default-data": _vm.freightBillInfo.inputDefaultData,
                "btn-type": _vm.freightBillInfo.btnType,
                "validation-tax-template": _vm.validationTaxTemplate,
              },
            }),
            _c("div", { staticClass: "text_right" }, [
              _vm._v(_vm._s(_vm.totalAmtMsg)),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.payment()
                },
              },
            },
            [_vm._v("저장")]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("취소")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }