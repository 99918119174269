<template>
  <div class="popup_wrap" style="width:1000px; height: 600px;">
    <button class="layer_close" @click="close()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.SETT060G010.013') }}</h1> <!-- 입금/계산서 정보입력 -->
      <div class="content_box">
        <FreightBillInfo
          ref="FBInfo"
          v-show="freightBillInfo.isShow.info"
          :dp-id="freightBillInfo.dpId"
          :bl-list="freightBillInfo.blList"
        ></FreightBillInfo>
        <FreightBillMethod
          ref="FBMethod"
          v-show="freightBillInfo.isShow.method"
          :err-arr="errArr.method"
          :clear-arr="clearArr.method"
          :dp-id="freightBillInfo.dpId"
          :input-default-data="freightBillInfo.inputDefaultData"
          :btn-type="freightBillInfo.btnType"
          :validation-method="validationMethod"
        ></FreightBillMethod>
        <FreightBillTax
          ref="FBTax"
          v-show="freightBillInfo.isShow.tax"
          :err-arr="errArr.taxTemplate"
          :clear-arr="clearArr.taxTemplate"
          :dp-id="freightBillInfo.dpId"
          :input-default-data="freightBillInfo.inputDefaultData"
          :btn-type="freightBillInfo.btnType"
          :validation-tax-template="validationTaxTemplate"
        ></FreightBillTax>
        <div class="text_right">{{ totalAmtMsg }}</div>
      </div>

      <div class="mt10 text_center">
        <a class="button blue lg" href="javascript:void(0)" @click="payment()">저장</a>
        <a class="button gray lg" href="javascript:void(0)" @click="close()">취소</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import freeTime from '@/api/rest/settle/freeTime'
import CalcIssue from '@/api/rest/settle/calcIssue'
import FreightBillInfo from '@/components/common/FreightBillInfo'
import FreightBillMethod from '@/components/common/FreightBillMethod'
import FreightBillTax from '@/components/common/FreightBillTax'
import { rootComputed } from '@/store/helpers'
let erp_popup = ''
export default {
  name: 'FreightBillPop',
  components: {
    FreightBillInfo,
    FreightBillMethod,
    FreightBillTax
  },
  props: [
    'freightBillInfo',
    'userDmdInfo',
    'eiCatCd'
  ],
  data: function () {
    return {
      dummy: '',
      errArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      clearArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      isShow: {
        info: true,
        method: true,
        tax: true
      },
      checkData: null
    }
  },
  computed: {
     ...rootComputed,
     totalAmtMsg () {
      let msg = ''
      let lang = this.auth.serviceLang
      //KOR, ENG, JPN, CHN

      if (this.$ekmtcCommon.isNotEmpty(this.freightBillInfo.totalAmt)) {
        const totalAmt = this.numberFormat(this.freightBillInfo.totalAmt)
        msg = this.$t('msg.SETT060G010.054', { totalAmt: totalAmt })
      }

      return msg
    }
  },
  watch: {

  },
  created () {},
  mounted: function () {
    //this.openErpPopup(2)
    console.log('freightBillInfo >>>> ', this.freightBillInfo)
  },
  methods: {

    payment () {
      let taxTemplate = this.$refs.FBTax.emitData('taxTemplate')
      let method = this.$refs.FBMethod.emitData('method')
      let info = this.$refs.FBInfo.emitData('info')
      let req = this.parseReqData(taxTemplate, method, info)
      let isValid = false

      if (method.payType === 'D') {
        if (this.validationTaxTemplate(taxTemplate, 'payBtn')) {
          isValid = true
        }
      } else {
        isValid = true
        //this.bankingInfoAndTax(req, method, info)
      }

      if (isValid) {
        this.checkHasCm134IFreetime(req, method, info)
      }
    },
    checkHasCm134IFreetime (req, method, info) {
      CalcIssue.checkHasCm134IFreetime({
        blNo: req.blNo,
        reqBizCatCd: req.reqBizCatCd
      }).then(response => {
        console.log('checkHasCm134IFreetime >>> ', response)
        this.checkData = response.data
        if (this.isEmpty(response.data)) {
          this.msgAlert(this.$t('msg.FARE010T010.023')) // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
        } else if (!this.isEmpty(response.data.sa206i)) {
          let obj = {
            onClose: this.close,
            onConfirm: this.cancelReq,
            customCloseBtnClass: 'button blue lg',
            customCloseBtnText: this.$i18n.t('msg.SETT060G010.021'),
            customConfirmBtnText: this.$i18n.t('msg.MYIN050T010.056'),
            useConfirmBtn: true,
            customConfirmBtnClass: 'button blue lg',
            message: this.$i18n.t('msg.ONEX070G050.016'), // 연장요청내역이 존재합니다. <br/> 취소 후 입금/계산서 발행하시겠습니까?
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
        // else if (!this.isEmpty(response.data.cm134i)) {
        //   let obj = {
        //     onClose: this.close,
        //     alertType: 'simple',
        //     customCloseBtnClass: 'button blue lg simplert-test1',
        //     customCloseBtnText: '닫기',
        //     useIcon: false,
        //     customClass: 'simple_alert',
        //     message: this.$t('msg.SETT060G010.055'), // 입금요청 확인건이 존재합니다.
        //     type: 'info'
        //   }
        //   this.$ekmtcCommon.alert(obj)
        } else {
          if (this.freightBillInfo.dpType === 'B' || this.freightBillInfo.dpType === 'R') {
            this.bankingInfoAndTax(req, method, info) //TODO 인보이스번호도 부모한테 받아서 같이 넘겨줘야함.
          } else {
              this.createDemDetInvoice(null, req.payType, method, info, req)
          }
        }
      }).catch(e => {
        this.checkData = null
        this.msgAlert(this.$t('msg.FARE010T010.023'))
        console.log(e)
      })
    },
    cancelReq () {
      freeTime.cancelSa206i(this.checkData.sa206i).then(response => {
        this.msgAlert(this.$t('msg.ONEX070G050.017'))
      }).catch(e => {
        console.log(e)
      })
    },
    createDemDetInvoice (resp, payType, method, info, req) {
      let invoiceCntrList = []
      for (let cntr of this.freightBillInfo.blList[0].cntrList) {
        invoiceCntrList.push(
          {
            cntrQty: cntr.cntrQty,
            cntrNo: cntr.cntrNo,
            invRno: cntr.invRno,
            cstNo: this.memberDetail.cstCd,
            ctrCd: this.memberDetail.userCtrCd,
            curCd: this.freightBillInfo.blList[0].currency,
            totAmt: cntr.totAmt,
            locAmt: cntr.totAmt,
            dcAmt: cntr.dcAmt,
            rmk: null,
            bizCatCd: this.freightBillInfo.dpType === 'B' ? '03' : '06', //?
            blNo: this.freightBillInfo.blList[0].blNo,
            demDetCatCd: this.freightBillInfo.blList[0].demDetCatCd,
            eiCatCd: this.freightBillInfo.blList[0].eiCatCd,
            demSeq: cntr.demSeq,
            freeEndDt: cntr.freeEndDt,
            extsDt: cntr.extsDt,
            freeDds: cntr.freeDds,
            ovrDds: cntr.ovrDds,
            dcRt: cntr.dcRt,
            freeStrDt: '',
            trfRate: 0, //?
            demDetAppNo: '',
            demDetTrfNo: ''
          }
        )
      }
      CalcIssue.createDemDetInvoice({
        invoiceCntrList: invoiceCntrList
      }).then(response => {
        if (response.data.rtnMsg === 'SUCC') {
          req.invIssNo = response.data.invIssNo
          this.bankingInfoAndTax(req, method, info)
        } else if (response.data.rtnMsg === 'REF') {
          this.msgAlert(this.$t('msg.SETT060G010.065'))
        } else if (response.data.rtnMsg === 'ISS') {
          this.msgAlert(this.$t('msg.SETT060G010.066'))
        } else {
          this.msgAlert(response.data.rtnMsg)
        }
        this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
        this.close()
      }).catch(e => {
        //
        console.log(e)
      })

      // CalcIssue.createDemDetInvoice({
      //   invoiceCntrList: invoiceCntrList
      // }).then(response => {
      //   if (response.data.rtnMsg === 'SUCC') {
      //     if (payType === 'N') {
      //       this.fnErpSave(resp.data.acNo, resp.data.erpReqNo, 3, method, info)
      //     } else {
      //       // this.msgAlert('결제되었습니다.')
      //       this.msgAlert(app.$t('msg.ONEX090P030.001')) // 신청하였습니다.
      //     }
      //   } else {
      //     this.msgAlert(response.data.rtnMsg)
      //   }
      //   this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
      //   this.close()
      // }).catch(e => {
      //    this.cancelPay('BANK', resp.data.acNo)
      //   console.log(e)
      // })
    },
    bankingInfoAndTax (req, method, info) {
      if (this.freightBillInfo.inputDefaultData !== undefined && this.freightBillInfo.inputDefaultData !== null && this.freightBillInfo.inputDefaultData.existTaxInfo === 'Y') {
        CalcIssue.onlyReturnTaxSave(req).then(response => {
          if (parseInt(response.data.result) === 1) {
            this.msgAlert('정상적으로 저장되었습니다.')
            this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
            this.close()
          } else {
            this.msgAlert('저장에 실패하였습니다.')
          }
        }).catch(e => {
          console.log(e)
        })
      } else {
        CalcIssue.bankingInfoAndTax(req).then(response => {
          if (parseInt(response.data.result) === 1) {
            if (this.freightBillInfo.dpType === 'B') {
              if (method.payType === 'N') {
                this.fnErpSave(response.data.acNo, response.data.erpReqNo, 3, method, info)
              } else {
                this.msgAlert('정상적으로 입력되었습니다.')
                this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
                this.close()
              }
            } else {
              if (parseInt(response.data.result) === 1) {
                if (this.freightBillInfo.flag) {
                  this.msgAlert('정상적으로 입력되었습니다.')
                  this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
                  this.close()
                } else {
                  if (method.payType === 'N') {
                    this.fnErpSave(response.data.acNo, response.data.erpReqNo, 3, method, info)
                  } else {
                    this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
                    this.msgAlert(app.$t('msg.ONEX090P030.001')) // 신청하였습니다.
                    this.close()
                  }
                }
              } else {
                this.msgAlert('결제에 실패하였습니다.')
              }
            }
          } else {
            this.msgAlert('결제에 실패하였습니다.')
            this.cancelPay('BANK', response.data.acNo)
          }
        }).catch(e => {
          console.log(e)
        })
      }
    },
    fnErpSave (reqAcNo, erpKmtcNo, cnt, method, info) {
      let data = {
        userId: method.bankLoginInfo.bnkId,
        userPw: method.bankLoginInfo.bnkPswd,
        acNo: method.bankLoginInfo.bnkAcct,
        mnrcvBnkCd: '088',
        mnrcvAcNo: '26204000149',
        amt: info.krwTotal,
        inptDraweName: reqAcNo,
        AcnoMemo: '',
        erpKmtcNo: erpKmtcNo
      }
      CalcIssue.tenUnderTransfer(data).then(response => {
          let resp = response.data
          if (resp.rtnCode !== '0000') {
            this.msgAlert(resp.rtnMsg)
          } else {
            if (!this.isEmpty(resp.outRecDto) && !this.isEmpty(resp.outRecDto.rtnCode) && !this.isEmpty(resp.outRecDto.rtnCode)) {
              this.cancelPay('N', acNo)
            } else {
              //openPopup
              this.openErpPopup(cnt, response, data, reqAcNo)
            }
          }
      }).catch(e => {
        this.msgAlert('결제에 실패하였습니다.')
        this.cancelPay('N', reqAcNo)
        console.log(e)
      })
    },
    openErpPopup (cnt, response, data, reqAcNo) {
      if (cnt === 1) {
        let url = 'https://cms.kbstar.com/quics?page=C066733'
        let name = '즉시 이체'
        let option = 'width = 880, height = 700, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      } else {
        let url = 'https://cms.kbstar.com/quics?page=C102879'
        let name = '간편 이체'
        let option = 'width = 360, height = 460, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      }
      this.chk_popup(response, data, reqAcNo)
    },
    chk_popup (response, data) {
      if (typeof (erp_popup) === 'undefined' || erp_popup.closed) {
        this.erpResult(response, data, reqAcNo)
      } else {
        setTimeout(() => {
          this.chk_popup(response, data)
        }, 3000)
      }
    },
    erpResult (response, data, reqAcNo) {
      let req = {
        userId: data.userId,
        userPw: data.userPw,
        erpregno: response.erpregno,
        erpAccNo: data.acNo
      }
      CalcIssue.tenUnderSel(req).then(response => {
        if (response.PrcssFnshYn === 'Y') {
          this.fnErpRegNoEdit(response.ERPRegiPrcssNo, reqAcNo)
        } else {
          //
        }
      }).catch(e => {
        console.log(e)
      })
    },
    fnErpRegNoEdit (erpNo, acNo) {
      let param = {
        acNo: acNo,
        erpNo: erpNo
      }
      let blList = [] //TODO get blList
      let linkBlNo = '' // ex ) a,b,c,d
      CalcIssue.fnErpRegNoEdit(param).then(response => {
         if (response.data.errCode === '2000') {
          this.msgAlert('결제되었습니다.')
          this.close()
        } else {
          this.msgAlert('..?')
        }
      }).catch(e => {
        console.log(e)
      })
    },
    parseReqData (taxTemplate, method, info) {
      let vrAccNoList = []
      let reqBizCatCd = ''

      if (this.freightBillInfo.btnType === 'OFC') {
        reqBizCatCd = '17'
      } else {
        reqBizCatCd = this.getBizCatCd(this.freightBillInfo.blList[0].demDetCatCd) // JamSin
      }

      let invReqRno = ''
      if (this.freightBillInfo.btnType === 'RETURN') {
        invReqRno = this.freightBillInfo.inputDefaultData.reqRno
      } else {
        invReqRno = this.freightBillInfo.invReqRno ? this.freightBillInfo.invReqRno : ''
      }

      let invRno = ''

      let res = {
        asBlNo: info.blList[0].blNo,
        reqBizCatCd: reqBizCatCd,
        blNo: info.blList[0].blNo,
        cntrList: info.blList[0].cntrList || [],
        demDetCatCd: info.blList[0].demDetCatCd,
        eiCatCd: info.blList[0].eiCatCd,
        payAcctList: method.depositInfoArr,
        taxTemplate: taxTemplate,
        type: 'insertBankInfo',
        flag: this.freightBillInfo.flag ? this.freightBillInfo.flag : '',
        invReqRno: invReqRno,

        payType: method.payType,
        dpType: this.freightBillInfo.dpType, //F: Freetime, B: Booking cancel
        erpBankCode: method.bankLoginInfo.bnkAcct,
        payReq: {},
        bookingInfo: this.freightBillInfo.bookingInfo,
        file: taxTemplate.file,
        overseasFileList: this.isEmpty(method.overseasFileList) ? [] : method.overseasFileList,

        dmdCatCd: '',
        invIssNo: (this.freightBillInfo.bookingInfo ? this.freightBillInfo.bookingInfo.invIssNo : info.blList[0].invIssNo), // 부킹 캔슬 시 사용 JamSin
        acshIssDt: taxTemplate.acshIssDt, // 부킹 캔슬 시 사용 JamSin
        invRno: invRno
      }
      if (method.payType === 'V') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        if (vrAccNoList.length === 0) {
          this.msgAlert('가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
          return false
        }
        res.payReq = {
          type: method.payType,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          file: taxTemplate.file
        }
      } else if (method.payType === 'N') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        res.payReq = {
          type: method.payType,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          erpBankCode: method.bankLoginInfo.bnkAcct,
          erpBankName: '',
          file: taxTemplate.file
        }
      }

      if (this.eiCatCd === 'O') {
        res.dmdCatCd = this.userDmdInfo.etDmdCatCd
      } else if (this.eiCatCd === 'I') {
        res.dmdCatCd = this.userDmdInfo.imDmdCatCd
      }

      // if (res.dmdCatCd === 'M') {
      //   res.dmdCatCd = 'C'
      // }
      // 프리타임, 반납은 영수화주로 처리
      res.dmdCatCd = 'R'

      res.demDetCatText = this.freightBillInfo.demDetCatText
      res.pageType = this.freightBillInfo.btnType
      res.payType = method.payType

      if (this.freightBillInfo.inputDefaultData !== undefined && this.freightBillInfo.inputDefaultData !== null && this.freightBillInfo.inputDefaultData.existTaxInfo === 'Y' &&
      this.freightBillInfo.inputDefaultData.reqRno !== undefined && this.freightBillInfo.inputDefaultData.reqRno !== null && this.freightBillInfo.inputDefaultData.reqRno !== '') {
        res.reqRno = this.freightBillInfo.inputDefaultData.reqRno
      }

      return res
    },
    cancelPay (type, acNo) {
      CalcIssue.cancelPay({ type: type, acNo: acNo }).then(response => {
        console.log(response)
      }).catch(e => {
        console.log(e)
      })
    },
    validationMethod (method, type) {
      // let errArr = []
      // let clearArr = []

      // for (let row of method.depositInfoArr) {
      //   if (this.isEmpty(row.payCur)) {
      //     errArr.push({ id: 'fbt-payCur-' + row.rowIdx, msg: '화폐를 선택해주세요.' })
      //   } else {
      //     clearArr.push('fbt-payCur-' + row.rowIdx)
      //   }

      //   if (this.isEmpty(row.bank)) {
      //     errArr.push({ id: 'fbt-bank-' + row.rowIdx, msg: '은행을 선택해주세요.' })
      //   } else {
      //     clearArr.push('fbt-bank-' + row.rowIdx)
      //   }

      //   if (this.isEmpty(row.accNo)) {
      //     errArr.push({ id: 'fbt-accNo-' + row.rowIdx, msg: '계좌번호를 선택해주세요.' })
      //   } else {
      //     clearArr.push('fbt-accNo-' + row.rowIdx)
      //   }
      //   if (this.isEmpty(row.depositPnm)) {
      //     errArr.push({ id: 'fbt-depositPnm-' + row.rowIdx, msg: '입금자를 입력해주세요.' })
      //   } else {
      //     clearArr.push('fbt-depositPnm-' + row.rowIdx)
      //   }

      //   if (this.isEmpty(row.depositAmt)) {
      //     errArr.push({ id: 'fbt-depositAmt-' + row.rowIdx, msg: '금액을 입력해주세요.' })
      //   } else {
      //     clearArr.push('fbt-depositAmt-' + row.rowIdx)
      //   }

      //   if (this.isEmpty(row.depositDt)) {
      //     errArr.push({ id: 'fbt-depositDt-' + row.rowIdx, msg: '입금일을 입력해주세요.' })
      //   } else {
      //     clearArr.push('fbt-depositDt-' + row.rowIdx)
      //   }
      // }

      // this.clearArr.method = clearArr
      // if (errArr.length === 0) {
      //   return true
      // } else {
      //   if (type === 'payBtn') {
      //     this.errArr.method = errArr
      //   }
      //   return false
      // }
      return true
    },
    validationTaxTemplate (taxTemplate, type) {
      let errArr = []
      let clearArr = []
      if (this.isEmpty(taxTemplate.rcivNm)) {
        errArr.push({ id: 'fbt-rcivNm', msg: '상호를 입력해주세요.' })
      } else {
        clearArr.push('fbt-rcivNm')
      }
      if (this.isEmpty(taxTemplate.rcivAddr)) {
        errArr.push({ id: 'fbt-rcivAddr', msg: '주소를 입력해주세요.' })
      } else {
        clearArr.push('fbt-rcivAddr')
      }
      if (this.isEmpty(taxTemplate.rcivBzrgNo)) {
        errArr.push({ id: 'fbt-rcivBzrgNo', msg: '사업자 번호를 입력해주세요.' })
      } else {
        clearArr.push('fbt-rcivBzrgNo')
      }
      // if (this.isEmpty(taxTemplate.file)) {
      //   errArr.push({ id: 'fbt-file', msg: '사업자 등록증을 첨부해주세요.' })
      // } else {
      //   clearArr.push('fbt-file')
      // }
        if (this.isEmpty(taxTemplate.emlAddr)) {
          errArr.push({ id: 'fbt-emlAddr', msg: 'email을 입력해주세요.' })
        } else {
          // let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
          let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (taxTemplate.emlAddr.match(regExp) == null) {
            errArr.push({ id: 'fbt-emlAddr', msg: '이메일 형식을 확인해 주세요.' })
          } else {
            clearArr.push('fbt-emlAddr')
          }
      }
      if (this.isEmpty(taxTemplate.rcivPicNm)) {
        errArr.push({ id: 'fbt-rcivPicNm', msg: '담당자를 입력해주세요.' })
      } else {
        clearArr.push('fbt-rcivPicNm')
      }
      if (this.isEmpty(taxTemplate.rcivCtfc)) {
        errArr.push({ id: 'fbt-rcivCtfc', msg: '연락처를 입력해주세요.' })
      } else {
        clearArr.push('fbt-rcivCtfc')
      }
      if (taxTemplate.acshIssKind === '02' && this.isEmpty(taxTemplate.acshIssDt)) {
        errArr.push({ id: 'fbt-obrdDt', msg: '계산서 발행일을 입력해주세요.' })
      } else {
        clearArr.push('fbt-obrdDt')
      }

      this.clearArr.taxTemplate = clearArr
      if (errArr.length === 0) {
        return true
      } else {
        if (type === 'payBtn') {
          this.errArr.taxTemplate = errArr
        }
        return false
      }
    },
    getBizCatCd (demDetCatCd) {
      let bizCatCd = null
      switch (demDetCatCd) {
        case '01':
          bizCatCd = '09'
          break
        case '02':
          bizCatCd = '10'
          break
        case '03':
          bizCatCd = '10'
          break
        case '08':
          bizCatCd = '16'
          break
        case '04':
          bizCatCd = ''
          break
        case '07':
          bizCatCd = '18'
          break
      }
      return bizCatCd
    },
    close () {
      setTimeout(() => {
          this.$emit('close')
        this.$refs.FBMethod.reset()
        this.$refs.FBTax.reset()
      }, 100)
    },
    msgAlert (msg) {
       let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: '닫기',
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>
